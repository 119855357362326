import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { tap, map } from "rxjs/operators";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { DataService } from "@shared/async-services/data.service";

@Injectable()
export class GridDataDiaryService extends BehaviorSubject<any[]> {
  public state: State = {
    skip: 0,
    take: 10,
  };

  // private BASE_URL = "agent/list";
  constructor(
    private dataService: DataService,
    ) {
    super([]);
  }

  public read(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    searchParameter:any,
    stateTake=10,
    outerSearch: any = null
  ) {
    this.fetch(action, searchtext, endpoint,searchParameter,stateTake, outerSearch)
      .pipe(tap((data) => {}))
      .subscribe((data) => {
       // this.rgService.totalSearchRecords = data.total;
        super.next(data);
      });
  }

  public fetch(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    searchParameter:any,
    stateTake=10,
    outerSearch: any = null
  ): Observable<any> {
    this.state.take=stateTake || 10;
    
    switch (action) {
      case "search": {
        let queryStr = `${toDataSourceRequestString(this.state)}`;
        const params = queryStr.split("&");
        const objSearch = Object.assign({});
        if (queryStr.indexOf("sort") > -1) {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.sortCol = params[1].split("=")[1].split("-")[0];
          objSearch.sortOrder = params[1].split("=")[1].split("-")[1];
          objSearch.pageSize = params[2].split("=")[1];
        } else {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.pageSize = params[1].split("=")[1];
          objSearch.searchText = searchtext;          
          
        }
        objSearch.agentId= searchParameter.agentIds;
        objSearch.handlerId = searchParameter.handlerIds;
        objSearch.productId = searchParameter.productIds;
        objSearch.status = searchParameter.statusIds;
        objSearch.type = searchParameter.typeIds;
        objSearch.minLYPremium = searchParameter. minLYPremium;
        return this.dataService.post(`${endpoint}`, objSearch).pipe(
          // Process the response.
          map((data: any): any => {
            return {
              // data: data.payload.userDetailsList,
              // total: data.payload.totalCount,
              data: data.items,
              total: data.totalCount,
            };
          })
        );
      }
      case "sort": {
        let queryStr = `${toDataSourceRequestString(this.state)}`;
        const params = queryStr.split("&");
        const objSearch = Object.assign({});
        objSearch.pageIndex = +params[0].split("=")[1] - 1;
        objSearch.sortCol = params[1].split("=")[1].split("-")[0];
        objSearch.sortOrder = params[1].split("=")[1].split("-")[1];
        objSearch.pageSize = params[2].split("=")[1];
        objSearch.searchText = searchtext;
        objSearch.agentId= searchParameter.agentIds;
        objSearch.handlerId = searchParameter.handlerIds;
        objSearch.productId = searchParameter.productIds;
        objSearch.status = searchParameter.statusIds;
        objSearch.type = searchParameter.typeIds;
        objSearch.minLYPremium = searchParameter. minLYPremium;
        return this.dataService.post(`${endpoint}`, objSearch).pipe(
          // Process the response.
          map((data: any): any => {
            return {
              // data: data.payload.userDetailsList,
              // total: data.payload.totalCount,
              data: data.items,
              total: data.totalCount,
            };
          })
        );
      }
      case "outerSearch": {
        let queryStr = `${toDataSourceRequestString(this.state)}`;
        const params = queryStr.split("&");
        const objSearch = Object.assign({});
        if (queryStr.indexOf("sort") > -1) {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.sortCol = params[1].split("=")[1].split("-")[0];
          objSearch.sortOrder = params[1].split("=")[1].split("-")[1];
          objSearch.pageSize = params[2].split("=")[1];
        } else {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.pageSize = params[1].split("=")[1];
          objSearch.searchText = searchtext;
          
        }
        objSearch.minLYPremium = searchParameter. minLYPremium;
        objSearch.agentId= searchParameter.agentIds;
        objSearch.handlerId = searchParameter.handlerIds;
        objSearch.productId = searchParameter.productIds;
        objSearch.status = searchParameter.statusIds;
        objSearch.type = searchParameter.typeIds;
        

        // objSearch.referenceNumber = outerSearch.referenceNumber;
        // objSearch.proposer = outerSearch.proposer;
        // objSearch.broker = outerSearch.broker;
        // objSearch.DateSaved = outerSearch.DateSaved;
        // objSearch.Premium = outerSearch.Premium;
        
        return this.dataService.post(`${endpoint}`, objSearch).pipe(
          // Process the response.
          map((data: any): any => {
            return {
              // data: data.payload.userDetailsList,
              // total: data.payload.totalCount,
              data: data.items,
              total: data.totalCount,
            };
          })
        );
      }
      case "": {
        let queryStr = `${toDataSourceRequestString(this.state)}`;
        const params = queryStr.split("&");
        const objSearch = Object.assign({});
        if (queryStr.indexOf("sort") > -1) {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.sortCol = params[1].split("=")[1].split("-")[0];
          objSearch.sortOrder = params[1].split("=")[1].split("-")[1];
          objSearch.pageSize = params[2].split("=")[1];
        } else {
          objSearch.pageIndex = +params[0].split("=")[1] - 1;
          objSearch.pageSize =params[1].split("=")[1]; 
          objSearch.searchText=""        ;
        }
        objSearch.agentId= searchParameter.agentIds;
        objSearch.handlerId = searchParameter.handlerIds;
        objSearch.productId = searchParameter.productIds;
        objSearch.status = searchParameter.statusIds;
        objSearch.type = searchParameter.typeIds;
        objSearch.minLYPremium = searchParameter. minLYPremium;
        queryStr =
        queryStr + "&skip=" + this.state.skip + "&take=" + this.state.take;
               
        // queryStr =
        //   queryStr + "&skip=" + this.state.skip + "&take=" + this.state.take;
        return this.dataService.post(`${endpoint}`, objSearch).pipe(
          // Process the response.
          map((data: any): any => {
            return {
              data: data.items,
              total: data.totalCount,
              // data: data.payload.userDetailsList,
              // total: data.payload.totalCount,
            };
          })
        );

       
      }
    }
  }

  queryAll(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    searchParameter:any,
    stateTake=10,
    outerSearch: any = null
  ): Observable<GridDataResult[]> {
    this.state.skip=0;
    this.state.take=stateTake;   
    return this.fetch(action,searchtext,endpoint,searchParameter,stateTake,outerSearch);
  }
  defaultLoad(
    action: string = "",
    searchtext: string = "",
    endpoint: string = "",
    searchParameter:any,
    stateTake=10,
    outerSearch: any = null
  ): Observable<GridDataResult[]> {
    this.state.skip=0;
    this.state.take=stateTake;   
    return this.fetch(action,searchtext,endpoint,searchParameter,stateTake,outerSearch);
  }
}
