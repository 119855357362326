import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription, filter } from 'rxjs';
import { GridDataPolicyService } from '@app/services/gridData.policy.service';
import { ListTable } from '@shared/models';
import { GridDataDiaryService } from '@app/services/gridData.diary.service';
import { EditDiaryComponent } from '../edit-diary/edit-diary.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'diary-detail',
  templateUrl: './diary-detail.component.html',
  styleUrl: './diary-detail.component.css'
})
@AutoUnsubscribe({ arrayName: "subscription" })
export class DiaryDetailComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public ProductData: Array<ListTable>;
  public ProductDataClone: Array<ListTable>;

  public AgentData: Array<ListTable>;
  public AgentDataClone: any;

  public StatusData: Array<ListTable>;
  public StatusDataClone: Array<ListTable>;
  filteredStatusData: Array<ListTable> = [];
  public TypeData: Array<ListTable>;
  public TypeDataClone: Array<ListTable>;

  public HandlerData: Array<ListTable>;
  public HandlerDataClone: Array<ListTable>;
  dairyDataList: any;
  gridDataView: any;
  diarystatusId: any;
  diaryTypeId: any;
  selectedAgentId: any;
  index = 0;
  agentId = 0;
  productId = 0;
  selectedTypeValue: Number;

  count: any;
  DairyApiBasePath = 'Insurance/Diary/SearchDiary';
  public totalCount = 0;
  searchText: any;
  isOuterSearch: boolean;
  public dairyState: State = {
    skip: 0,
    take: 10,
    filter: { filters: [], logic: "or" },
  };

  searchParameters = {
    agentIds: null,
    handlerIds: null,
    statusIds: null,
    productIds: null,
    typeIds: null,
    minLYPremium: null
  };
  toggleAllText = "Select All";
  public selectedProducts: number[] = [];

  public checkableSettings: any = {
    checkAll: true,
    checkOnClick: true
  };
  isLapsedSelected: boolean;
  constructor(private fb: FormBuilder, private insuranceService: InsuranceService, private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private confirMationDialogService: ConfirmationDialogService,
    private router: Router,
    private gridDataService: GridDataDiaryService,private authService: AuthService
  ) {
  }
  ngOnDestroy() {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const claimMappings = {
      'CANVIEWBBGUHOUSE': 1,
      'CANVIEWCOMCOMBINED': 2,
      'CANVIEWCONTRAALLRISK': 4,
      'CANVIEWELPL': 5,
      'CANVIEWOFFICE': 6,
      'CANVIEWPERSNLACCIDENT': 7,
      'CANVIEWPROFINDEM': 8,
      'CANVIEWPROPOWNERS': 9,
      'CANVIEWPUBHOUSEREST': 10,
      'CANVIEWSELFBUILD': 11,
      'CANVIEWSHOPRETAIL': 12,
      'CANVIEWREALESTATE': 13,
    };
  
    this.subscription.add(
      this.insuranceService.geProducts().subscribe((data: Array<ListTable>): void => {
        const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
        if (auth && auth.claims && auth.claims.length > 0) {
          data = data.filter(product =>
            auth.claims.some(claim => claimMappings[claim] === product.value)
          );
        }
        this.ProductData = data; 
        this.ProductDataClone = Object.assign([], data); 
      })
    );

    this.insuranceService.getAgent().subscribe((data: Array<ListTable>): void => {
      this.AgentData = data;
      this.AgentDataClone = Object.assign([], data);
      console.log(this.AgentData);
    });

    this.insuranceService.getDiaryStatus().subscribe((data: Array<ListTable>): void => {
      this.StatusData = data;
      this.StatusDataClone = Object.assign([], data);
      this.filterStatusData();
    });

    this.insuranceService.getDiaryType().subscribe((data: Array<ListTable>): void => {
      this.TypeData = data;
      this.TypeDataClone = Object.assign([], data);
      this.selectedTypeValue = this.TypeData[0]?.value;
    });

    this.insuranceService.GetAllSystemAgent().subscribe((data: Array<ListTable>): void => {
      this.HandlerData = data;
      this.HandlerDataClone = Object.assign([], data);
      this.authService.isLogin.subscribe(t => {              
        this.HandlerData = data;
        this.HandlerDataClone = Object.assign([], data);
        if (t&& this.authService.authentication) {
          if(this.authService.authentication.userId!=null&&this.authService.authentication.userId!=0)
          this.searchParameters.handlerIds=[this.authService.authentication.userId]
        }
      });
    });
    this.searchParameters.handlerIds=[this.authService.authentication.userId];
    this.searchParameters.statusIds = [1];
    this.searchParameters.typeIds = [1];
    // this.submit();
    this.gridDataService.read(
      this.isOuterSearch ? "outerSearch" : "",
      "",
      this.DairyApiBasePath,
      this.searchParameters,
    );
    this.dairyDataList = this.gridDataService;
    this.subscription.add(
      this.dairyDataList.subscribe((data: any): void => {
        if (data != null && data != undefined) {
          this.count = data.total;
        }
      })
    );
  }

  filterStatusData(): void {
    if (this.selectedTypeValue === 3) {
      this.searchParameters.typeIds = [3];
      this.filteredStatusData = [...this.StatusData, { text: 'Lapsed', value: 4, code: null }];
      const allowedProducts = [2, 5, 6, 10, 12];
      this.ProductData = this.ProductData.filter(x => allowedProducts.includes(x.value.valueOf()));
      this.DairyApiBasePath = 'Insurance/Diary/SearchRenewalLapsedDiary';
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameters,
      );
      this.dairyDataList = this.gridDataService;
      this.subscription.add(
        this.dairyDataList.subscribe((data: any): void => {
          if (data != null && data != undefined) {
            this.count = data.total;
          }
        })
      );
    } else {
      this.filteredStatusData = this.StatusData.filter(item => item.value !== 4);
    }
  }

  onTypeValueChange(): void {
    this.filterStatusData();
  }

  isAllSelected(data: Array<any>, selectedItems: Array<any>): boolean {
    if(selectedItems && data)
    return data.length > 0 && data.length === selectedItems.length;
  }

  isItemSelected(item: any, selectedItems: Array<any>): boolean {
    if(selectedItems && item)
    return selectedItems.includes(item.value);
  }

  toggleSelectAll(dropdownType: string, event: any) {
    const isChecked = event.target.checked;
    switch (dropdownType) {
      case 'handler':
        this.searchParameters.handlerIds = isChecked ? this.HandlerData.map(d => d.value) : [];
        break;
      case 'product':
        this.searchParameters.productIds = isChecked ? this.ProductData.map(d => d.value) : [];
        break;
      case 'status':
        this.searchParameters.statusIds = isChecked ? this.StatusData.map(d => d.value) : [];
        break;
      case 'type':
        this.searchParameters.typeIds = isChecked ? this.TypeData.map(d => d.value) : [];
        break;
      case 'agent':
        this.searchParameters.agentIds = isChecked ? this.AgentData.map(d => d.value) : [];
        break;
      default:
        break;
    }
  }

  submit() {

    if (!this.searchParameters.productIds || this.searchParameters.productIds === null) {
      this.toasterService.warning('Please select product');
      return;
    }
    this.isLapsedSelected = this.filteredStatusData.some(item => item.value === 4 || this.searchParameters.statusIds.includes(4));
    if (this.searchParameters.agentIds?.length > 0 || this.searchParameters.statusIds?.length > 0 || this.searchParameters.productIds?.length > 0 || this.searchParameters.handlerIds?.length > 0 || this.searchParameters.typeIds?.length > 0 || this.searchParameters.minLYPremium?.length == null) {
      // var agentIds=this.selectedAgentId.forEach(x=>x.value);
      //var agentId=this.selectedAgentId.map(function(a) {return a.value;});
      //var agentId=[76];
      const searchParameters = {
        agentIds: this.searchParameters.agentIds?.length > 0 ? this.searchParameters.agentIds?.agentId : [],
        productIds: this.searchParameters.productIds?.length > 0 ? this.searchParameters.productIds : [],
        handlerIds: this.searchParameters.handlerIds?.length > 0 ? this.searchParameters.handlerIds : [],
        statusIds: this.searchParameters.statusIds?.length > 0 ? this.searchParameters.statusIds : [],
        typeIds: this.searchParameters.typeIds?.length > 0 ? this.searchParameters.typeIds : [],
        minLYPremium: this.searchParameters.minLYPremium?.length > 0 ? this.searchParameters.minLYPremium : null,
        isLapsedSelected: this.isLapsedSelected
      };

      if(this.isLapsedSelected == true || this.selectedTypeValue === 3){
        this.DairyApiBasePath = 'Insurance/Diary/SearchRenewalLapsedDiary'
      }
      else{
        this.DairyApiBasePath = 'Insurance/Diary/SearchDiary'
      }
      this.dairyState.skip = 0;
      this.gridDataService.state = this.dairyState;
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameters,

      );
      this.dairyDataList = this.gridDataService;

      this.dairyDataList.subscribe((data: any): void => {
        if (data) {
          this.count = data.total;
          //this.loadExterNalScript();  

        }
      })

    }
    else {
      this.toasterService.info('Please select atleast one filter for search!.');
      this.dairyDataList.next(null); // clears grid data
      this.dairyState.skip = 0;
    }
  }
  public dataStateChangeDairy(state: DataStateChangeEvent): void {
    if (this.searchText != undefined && this.searchText.length > 2) {
      this.gridDataService.state = state;
      this.dairyState = state;
      this.filterData(true);
    } else {
      this.gridDataService.state = state;
      this.dairyState = state;
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameters,

      );
    }
  }

  filterData(isfromstatechange: Boolean = false) {
    if (this.searchText == null) {
      return this.gridDataService.defaultLoad(
        "",
        this.searchText,
        this.DairyApiBasePath,
        this.searchParameters,
      );
    }

    if (!isfromstatechange) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 10;
    }
    if (this.searchText.length > 2) {
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "search",
        this.searchText,
        this.DairyApiBasePath,
        this.searchParameters,
      );
    } else if (this.searchText.length === 0) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 10;
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameters
      );
    }
    this.index = this.index + 1;
    this.dairyDataList = this.gridDataService;
  }

  editDiary(diaryId) {
    const modalref = this.modalService.open(EditDiaryComponent, {
      backdrop: "static",
      backdropClass: "modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "700",
    });
    modalref.componentInstance.diaryId = diaryId;
    modalref.result.then((data: any) => {
      if (data != null) {
        this.gridDataService.read(
          "",
          "",
          this.DairyApiBasePath,
          this.searchParameters,);
      }
    });
  }

  goToQuote(policyId, productcode) {
    let encryptedqueryparams: { policyId: number; };
    encryptedqueryparams = { policyId: policyId }
    console.log(this.insuranceService.encryptData(encryptedqueryparams))
    if(productcode == "Bbg"){
      this.router.navigate(['product-tm/bbg-quote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if(productcode == "Pa"){
      this.router.navigate(['product-tm/pa-quote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if(productcode == "Pi"){
      this.router.navigate(['product-tm/pi-quote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if(productcode == "Car"){
      this.router.navigate(['product/car-quote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if(productcode == "Sb"){
      this.router.navigate(['product/sbquote-details'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if(productcode == "Rei"){
      this.router.navigate(['product/reiquote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    }
    if (productcode == "ELPL")
      this.router.navigate(['product/quote-details'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    else if(productcode == "Fcc" || productcode == "Off" || productcode == "Shop" || productcode == "Lei")
      this.router.navigate(['product/commquote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
  }

  goToPolicy(policyId, productcode) {
    let encryptedPolicyqueryparams: { policyId: number; };
    encryptedPolicyqueryparams = { policyId: policyId }
    console.log(this.insuranceService.encryptData(encryptedPolicyqueryparams))
    if (productcode == "ELPL")
      this.router.navigate(['product/policy-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedPolicyqueryparams) } });
    else
      this.router.navigate(['product/ccpolicy-dashboard'], { queryParams:  encryptedPolicyqueryparams  });
  }

  handlerFilter(value) {
    this.HandlerData = this.HandlerDataClone.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  productFilter(value) {
    this.ProductData = this.ProductDataClone.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  agentFilter(value) {
    this.AgentData = this.AgentDataClone.filter(
      (s) => s.agentName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}


