
<div class="card h-100 blankpage-height">
    <div class="card-body d-flex justify-content-between align-items-center ps-3 border-bottom">
        <h5 class="mb-0">Diary Details</h5>
        <span class="product-name-label"> <label class="text-white mb-0">Total Records : </label><span class="font-weight-semibold"> &nbsp;{{ this.count ||
                0}}</span></span>
    </div>
    
   
      

                <div class="card mb-3">
                    <div class="card-body">
                        <div class="row mt-0">
                            <div class="row mb-2 g-2 align-items-center">

                                <div class="col-md-3">
                                    <label for="type">Type</label>
                                    <kendo-combobox
                                        [data]="TypeData"
                                        class="w-100"
                                        [filterable]="true"
                                        [textField]="'text'"
                                        [valueField]="'value'"
                                        [valuePrimitive]="true"
                                        [(ngModel)]="selectedTypeValue"
                                        (valueChange)="onTypeValueChange()"
                                        >
                                        </kendo-combobox>
                                </div>

                                <div class="col-md-3 kmulti-select">
                                    <label for="handler">Handler</label>
                                    <!-- [checkboxes]="{ checkOnClick: false }"   -->
                                    <kendo-multiselect
                                   
                                    id="handler"
                                        class="w-100"
                                        placeholder="Choose handler(s)"
                                        [data]="HandlerData"
                                        [textField]="'text'"  [valueField]="'value'"
                                        aria-multiselectable="true" 
                                        [(ngModel)]="searchParameters.handlerIds"  
                                        [valuePrimitive]="true"
                                        >
                                        <!-- [checkableSettings]="checkableSettings" -->
                                        <ng-template kendoMultiSelectHeaderTemplate>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="isAllSelected(HandlerData, searchParameters.handlerIds)"
                                                    (change)="toggleSelectAll('handler', $event)"
                                                />
                                                {{ toggleAllText }}
                                            </label>
                                        </ng-template>
                                    
                                        <!-- Item template with custom checkbox -->
                                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="isItemSelected(dataItem, searchParameters.handlerIds)"
                                                />
                                                {{ dataItem.text }}
                                            </label>
                                        </ng-template>
                                    </kendo-multiselect>
                                </div>
                                
                                <div class="col-md-3 kmulti-select">
                                    <label for="product">Product</label>
                                    <kendo-multiselect
                                    aria-multiselectable="true"
                                    id="product"
                                    class="w-100"
                                    placeholder="Select Product"
                                    [data]="ProductData"
                                    [textField]="'text'"  [valueField]="'value'"
                                    [(ngModel)]="searchParameters.productIds" 
                                    [valuePrimitive]="true"
                                    >
                                    <ng-template kendoMultiSelectHeaderTemplate>
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="isAllSelected(ProductData, searchParameters.productIds)"
                                                (change)="toggleSelectAll('product', $event)"
                                            />
                                            {{ toggleAllText }}
                                        </label>
                                    </ng-template>
                                
                                    <!-- Item template with custom checkbox -->
                                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="isItemSelected(dataItem, searchParameters.productIds)"
                                            />
                                            {{ dataItem.text }}
                                        </label>
                                    </ng-template>
                                </kendo-multiselect>
                        
                                </div>

                                <div class="col-md-3 kmulti-select">
                                    <label for="status">Status</label>
                                    <kendo-multiselect
                                       
                                        id="status"
                                        class="w-100"
                                        placeholder="Select status"
                                        [data]="filteredStatusData"
                                        [textField]="'text'"  [valueField]="'value'"
                                        aria-multiselectable="true"
                                        [(ngModel)]="searchParameters.statusIds" 
                                        [valuePrimitive]="true"
                                        >
                                        <ng-template kendoMultiSelectHeaderTemplate>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="isAllSelected(StatusData, searchParameters.statusIds)"
                                                    (change)="toggleSelectAll('status', $event)"
                                                />
                                                {{ toggleAllText }}
                                            </label>
                                        </ng-template>
                                    
                                        <!-- Item template with custom checkbox -->
                                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="isItemSelected(dataItem, searchParameters.statusIds)"
                                                />
                                                {{ dataItem.text }}
                                            </label>
                                        </ng-template>
                                    </kendo-multiselect>
                                </div>

                                <div class="col-md-2 kmulti-select">
                                    <label for="agent">Agent</label>
                                    <kendo-multiselect
                                       
                                        id="agent"
                                        class="w-100"
                                        placeholder="Select Agent"
                                        [data]="AgentData"
                                        [textField]="'agentName'"  [valueField]="'agentId'"
                                        aria-multiselectable="true"
                                        [(ngModel)]="searchParameters.agentIds" 
                                        [valuePrimitive]="true"  
                                       >
                                       <ng-template kendoMultiSelectHeaderTemplate>
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="isAllSelected(AgentData, searchParameters.agentIds)"
                                                (change)="toggleSelectAll('agent', $event)"
                                            />
                                            {{ toggleAllText }}
                                        </label>
                                    </ng-template>
                                
                                    <!-- Item template with custom checkbox -->
                                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                        <label>
                                            <input
                                                type="checkbox"
                                                [checked]="isItemSelected(dataItem, searchParameters.agentIds)"
                                            />
                                            {{ dataItem.agentName }}
                                        </label>
                                    </ng-template>
                                    </kendo-multiselect>
                                </div>
                        
                        
                                <div class="col-md-auto col-auto if-no-label">
                                        <input *ngIf="selectedTypeValue === 3" field="searchText" [(ngModel)]="searchParameters.minLYPremium" style="margin-right: 10px;" type="text" placeholder="Show Premiums From">
                                        <button type="button" class="btn btn-primary" (click)="submit()">
                                            Search
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            

        <div class="row">
            <div class="col-md-12 optis-grid">
                
                    <kendo-grid *ngIf="selectedTypeValue === 3" [data]="dairyDataList | async" [pageSize]="dairyState.take" [skip]="dairyState.skip" [sort]="dairyState.sort"
                                (dataStateChange)="dataStateChangeDairy($event)"  [scrollable]="'scrollable'" 
                                [pageable]="true"  [sortable]="true" [resizable]="true">
                      
                        <kendo-grid-column field="diaryStatus" title="Status" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <!-- <ng-template kendoGridCellTemplate let-dataItem>
                               <a (click)="getQuote()">{{dataItem?.Ref}}</a>
                            </ng-template> -->
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="badge bg-danger" *ngIf="dataItem.diaryStatusId==1">Outstanding</span>
                                <span class="badge bg-success" *ngIf="dataItem.diaryStatusId==2">SignedOff</span>
                                <span class="badge bg-secondary" *ngIf="dataItem.diaryStatusId==3">n.a.</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="fullName" title="Action By" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }" >
                            
                        </kendo-grid-column>
                        <kendo-grid-column field="assignUserName" title="Assigned" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="diaryId" title="Diary Id" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a (click)="editDiary(dataItem?.diaryId)"> {{dataItem?.diaryId}}</a>
                           
                        </ng-template>
                        </kendo-grid-column>                    
                        <kendo-grid-column field="tradingName" title="Agent" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="productName" title="Product"[headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }" >
                        </kendo-grid-column>
                        <kendo-grid-column field="certNo" title="Cert #"[headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }" >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a (click)="goToPolicy(dataItem?.policyId,dataItem?.productCode)"> {{dataItem?.certNo}}</a>
                        </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="lyPremium" title="LY Premium"[headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }" >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.productCode === 'ELPL' ? dataItem.lyPremium : dataItem.lyPremiumCC }}
                            </ng-template>
                        </kendo-grid-column>
                        
                        <kendo-grid-column field="notes" title="Risk Info" [headerStyle]="{ width: '200px' }"
                            [style]="{ width: '200px' }">
                             <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem?.businessName}}<br />
                                <b>Renewal :</b> {{dataItem?.inceptionDate |date}}<br />
                                <!-- <b>Renewal Quote :</b> <a (click)="goToQuote(dataItem?.policyId,dataItem?.productCode)"> {{dataItem?.quoteRefNo}}</a> - {{dataItem.productCode === 'ELPL' ? dataItem.premium : dataItem.premiumCC }}<br /> -->
                                <b>Renewal Quote :</b> <a (click)="goToQuote(dataItem?.policyId,dataItem?.productCode)"> {{dataItem?.renewalQuoteRef}}</a> - {{dataItem?.renewalQuotePremium }}<br />
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="notes" title="Notes" [headerStyle]="{ width: '200px' }"
                        [style]="{ width: '200px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="productName" title="Action"[headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }" >
                        </kendo-grid-column>
                    
                    </kendo-grid>

                    <kendo-grid *ngIf="selectedTypeValue === 1" [data]="dairyDataList | async" [pageSize]="dairyState.take" [skip]="dairyState.skip" [sort]="dairyState.sort"
                                (dataStateChange)="dataStateChangeDairy($event)"  [scrollable]="'scrollable'" 
                                [pageable]="true"  [sortable]="true" [resizable]="true">
                      
                        <kendo-grid-column field="diaryStatus" title="Status" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <!-- <ng-template kendoGridCellTemplate let-dataItem>
                               <a (click)="getQuote()">{{dataItem?.Ref}}</a>
                            </ng-template> -->
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="badge bg-danger" *ngIf="dataItem.diaryStatusId==1">Outstanding</span>
                            <span class="badge bg-success" *ngIf="dataItem.diaryStatusId==2">SignedOff</span>
                            <span class="badge bg-secondary" *ngIf="dataItem.diaryStatusId==3">n.a.</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="fullName" title="Action By" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">

                    </kendo-grid-column>
                    <kendo-grid-column field="createddate" title="Created On" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.createdDate | date: "dd/MM/yyyy" }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="assignUserName" title="Assigned" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="diaryId" title="Diary Id" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a (click)="editDiary(dataItem?.diaryId)"> {{dataItem?.diaryId}}</a>

                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="tradingName" title="Agent" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="productName" title="Product" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>

                    <kendo-grid-column field="notes" title="Risk Info" [headerStyle]="{ width: '200px' }"
                        [style]="{ width: '200px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            Quote : <a (click)="goToQuote(dataItem?.policyId,dataItem?.productCode)">
                                {{dataItem?.quoteRefNo}}</a><br />
                            {{dataItem?.businessName}}<br />
                            Inception : {{dataItem?.inceptionDate |date}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="referInfo" title="Refer Info" [headerStyle]="{ width: '200px' }"
                        [style]="{ width: '200px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem?.handlerName}} | {{dataItem?.mobile}}|
                            {{dataItem?.email}}
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>

            </div>
        </div>
    
</div>